import _ from "underscore";
import React from "react";
import Section from "./Section.js";
import I18n from "./I18n.js";
import LocaleContext from "./LocaleContext.js";
import { Locales } from "../constants.js";

const experiences = [
    {
        id: "xp1",
        image: {
            de: "GoodPractice_Candli.png",
            fr: "GoodPractice_Candli_FR.png",
            en: "GoodPractice_Candli_EN.png"
        },
        file: {
            de: "GoodPractice_Candli.pdf",
            fr: "GoodPractice_Candli_FR.pdf",
            en: "GoodPractice_Candli_EN.pdf"
        }
    },
    {
        id: "xp2",
        image: {
            de: "DE_GoodPractice_Herby_2024.png",
            en: "EN_GoodPractice_Herby.png"
        },
        file: {
            de: "DE_GoodPractice_Herby_2024.pdf",
            en: "EN_GoodPractice_Herby.pdf"
        }
    },
    {
        id: "xp3",
        image: {
            de: "TestbedMagazin.png"

        },
        file: {
            de: "Testbed_Magazin_29.07.2024.pdf"

        }
    }
];

const Experience = props => {
    const { experience } = props;
    return (
        <li className="col-4-12 col-tablet-1-2 col-phablet-1-1 ae-2">
            <a href={"/static/experiences/" + experience.file} target="_blank">
                <div className="sc">
                    <div className="sc-img">
                        <img
                            alt={experience.name}
                            src={"/static/img/experiences/" + experience.image}
                        />
                    </div>
                    <div
                        className="multi-select-dropdown"
                        style={{
                            position: "relative",
                            display: "inline-block",
                            textAlign: "center",
                            width: "100%",
                            marginTop: "8px"
                        }}
                    >
                        <button>
                            <I18n label="Download" />
                        </button>
                    </div>
                </div>
            </a>
        </li>
    );
};

const translateExperiences = ({ experiences, locale }) => {
    return _.compact(
        _.map(experiences, experience => {
            if (!experience.file?.[locale]) {
                return;
            }
            return {
                ...experience,
                file: experience.file && experience.file[locale],
                image: (experience.image && experience.image[locale]) || "",
                name: (experience.name && experience.name[locale]) || "",
                url: experience.url && experience.url[locale]
            };
        })
    );
};

const TestbedExperienceFromPractice = props => {
    const { locale } = React.useContext(LocaleContext);
    const translatedXps = translateExperiences({ experiences, locale });
    return (
        <Section
            scrollId="experienceFromPractice"
            sectionProps={{
                className: "slide whiteSlide",
                "data-name": "experiencefrompractice"
            }}
        >
            <div className="content">
                <div className="container">
                    <div
                        className="fix-10-12 toCenter"
                        style={{ textAlign: "center" }}
                    >
                        <h1 className="ae-1 fromLeft header-11 light">
                            <I18n label="TB_ExperienceFromPractice" />
                        </h1>
                    </div>
                    <div className="fix-11-12">
                        <div
                            className="ae-2 light"
                            style={{ textAlign: "center" }}
                        >
                            <I18n label="TB_ExperienceText" markdown />
                        </div>
                    </div>
                    <div className="fix-custom">
                        <ul
                            className="flex flexTop member-container popupTrigger controller fromBottom"
                            data-popup-id="member"
                            data-slider-id="member"
                        >
                            {translatedXps.map(experience => {
                                return (
                                    <Experience
                                        key={experience.id}
                                        experience={experience}
                                    />
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </Section>
    );
};

export default TestbedExperienceFromPractice;
