import React from "react";
import Section from "./Section.js";
import I18n from "./I18n.js";
import LocaleContext from "./LocaleContext.js";
import { Locales } from "../constants.js";

const getTestbedImage = props => {
    const { locale } = props;
    console.log("getTestbedImage", locale);
    switch (locale) {
        case Locales.DE: {
            return "/static/img/testbed/testbed.png";
        }
        case Locales.FR: {
            return "/static/img/testbed/testbed_fr.png";
        }
        case Locales.EN: {
            return "/static/img/testbed/testbed_en.png";
        }
        default: {
            return "/static/img/testbed/testbed.png";
        }
    }
};

const TestbedHowItWorks = props => {
    const { locale } = React.useContext(LocaleContext);
    return (
        <Section
            scrollId="howitworks"
            sectionProps={{
                className: "slide whiteSlide",
                "data-name": "howitworks"
            }}
        >
            <div className="content">
                <div className="container">
                    <div className="wrap">
                        <div className="fix-10-12 toCenter">
                            <h1 className="ae-1 fromLeft header-11 light">
                                <I18n label="TB_HowItWorks" />
                            </h1>
                        </div>
                        <div className="fix-11-12">
                            <div className="ae-2 light">
                                <div
                                    style={{
                                        paddingTop: "10px",
                                        paddingBottom: "24px"
                                    }}
                                >
                                    <img
                                        src={getTestbedImage({ locale })}
                                        width="100%"
                                    />
                                </div>
                                <I18n label="TB_HowItWorksText" markdown />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Section>
    );
};

export default TestbedHowItWorks;
